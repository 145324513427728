import React, { useEffect, useState } from 'react';
import api from '../utils/api';

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalClients: 0,
        upcomingPayments: [],
        recentActivity: []
    });

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const [clients] = await Promise.all([
                    api.get('/clients')
                ]);

                const upcomingPayments = clients.data
                    .filter(client => {
                        const daysUntilPayment = new Date(client.next_payment_date) - new Date();
                        return daysUntilPayment <= 30 * 24 * 60 * 60 * 1000; // 30 days
                    })
                    .sort((a, b) => new Date(a.next_payment_date) - new Date(b.next_payment_date));

                setStats({
                    totalClients: clients.data.length,
                    upcomingPayments,
                    recentActivity: clients.data.slice(-5).reverse()
                });
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchStats();
    }, []);

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-white rounded-lg shadow p-6">
                    <h3 className="text-lg font-semibold mb-2">Total Clients</h3>
                    <p className="text-3xl font-bold text-blue-600">{stats.totalClients}</p>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow">
                <div className="p-6">
                    <h3 className="text-lg font-semibold mb-4">Upcoming Payments</h3>
                    <div className="space-y-4">
                        {stats.upcomingPayments.map(client => (
                            <div key={client.id} className="flex justify-between items-center border-b pb-2">
                                <div>
                                    <p className="font-medium">{client.client_name}</p>
                                    <p className="text-sm text-gray-500">{client.project_name}</p>
                                </div>
                                <div className="text-right">
                                    <p className="font-medium">${client.annual_price}</p>
                                    <p className="text-sm text-gray-500">
                                        Due: {new Date(client.next_payment_date).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow">
                <div className="p-6">
                    <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
                    <div className="space-y-4">
                        {stats.recentActivity.map(client => (
                            <div key={client.id} className="flex justify-between items-center border-b pb-2">
                                <div>
                                    <p className="font-medium">{client.client_name}</p>
                                    <p className="text-sm text-gray-500">{client.project_name}</p>
                                </div>
                                <p className="text-sm text-gray-500">
                                    Added: {new Date(client.created_at).toLocaleDateString()}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
