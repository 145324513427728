import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import api from '../utils/api';
import io from 'socket.io-client';
import Peer from 'simple-peer';

const Chat = () => {
    const { user } = useAuth();
    const [socket, setSocket] = useState(null);
    const [rooms, setRooms] = useState([]);
    const [activeRoom, setActiveRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [showNewRoom, setShowNewRoom] = useState(false);
    const [newRoomName, setNewRoomName] = useState('');
    const [isInCall, setIsInCall] = useState(false);
    const [stream, setStream] = useState(null);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState(null);
    const [callerSignal, setCallerSignal] = useState(null);
    const [callAccepted, setCallAccepted] = useState(false);
    const userVideo = useRef();
    const partnerVideo = useRef();
    const peerRef = useRef();

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_WS_URL, {
            auth: { token: localStorage.getItem('token') }
        });

        newSocket.on('connect', () => {
            console.log('Connected to socket');
            newSocket.emit('join-rooms');
        });

        newSocket.on('new-message', (message) => {
            setMessages(prev => [...prev, message]);
        });

        newSocket.on('incoming-call', ({ signal, from }) => {
            setReceivingCall(true);
            setCaller(from);
            setCallerSignal(signal);
        });

        setSocket(newSocket);

        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await api.get('/chat/rooms');
                setRooms(response.data);
            } catch (error) {
                console.error('Error fetching rooms:', error);
            }
        };

        fetchRooms();
    }, []);

    useEffect(() => {
        if (activeRoom) {
            const fetchMessages = async () => {
                try {
                    const response = await api.get(`/chat/rooms/${activeRoom.id}/messages`);
                    setMessages(response.data);
                } catch (error) {
                    console.error('Error fetching messages:', error);
                }
            };

            fetchMessages();
        }
    }, [activeRoom]);

    const startCall = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });
            setStream(mediaStream);
            userVideo.current.srcObject = mediaStream;

            const peer = new Peer({
                initiator: true,
                trickle: false,
                stream: mediaStream
            });

            peer.on('signal', (signal) => {
                socket.emit('call-user', {
                    room_id: activeRoom.id,
                    signal: signal
                });
            });

            peer.on('stream', (remoteStream) => {
                partnerVideo.current.srcObject = remoteStream;
            });

            peerRef.current = peer;
            setIsInCall(true);
        } catch (error) {
            console.error('Error starting call:', error);
        }
    };

    const answerCall = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });
            setStream(mediaStream);
            userVideo.current.srcObject = mediaStream;

            const peer = new Peer({
                initiator: false,
                trickle: false,
                stream: mediaStream
            });

            peer.on('signal', (signal) => {
                socket.emit('answer-call', {
                    room_id: activeRoom.id,
                    signal: signal
                });
            });

            peer.on('stream', (remoteStream) => {
                partnerVideo.current.srcObject = remoteStream;
            });

            peer.signal(callerSignal);
            peerRef.current = peer;
            setCallAccepted(true);
            setIsInCall(true);
            setReceivingCall(false);
        } catch (error) {
            console.error('Error answering call:', error);
        }
    };

    const endCall = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        if (peerRef.current) {
            peerRef.current.destroy();
        }
        setStream(null);
        setIsInCall(false);
        setCallAccepted(false);
        setReceivingCall(false);
    };

    const handleCreateRoom = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/chat/rooms', {
                name: newRoomName,
                type: 'group',
                participants: [] // Add participant selection later
            });
            setRooms([...rooms, response.data]);
            setShowNewRoom(false);
            setNewRoomName('');
        } catch (error) {
            console.error('Error creating room:', error);
        }
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim() && socket && activeRoom) {
            socket.emit('send-message', {
                room_id: activeRoom.id,
                content: newMessage.trim()
            });
            setNewMessage('');
        }
    };

    return (
        <div className="h-[calc(100vh-theme(spacing.16))] flex">
            {/* Rooms sidebar */}
            <div className="w-64 bg-gray-50 border-r p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Chats</h2>
                    <button
                        onClick={() => setShowNewRoom(true)}
                        className="text-blue-500 hover:text-blue-700"
                    >
                        New
                    </button>
                </div>
                <div className="space-y-2">
                    {rooms.map(room => (
                        <button
                            key={room.id}
                            onClick={() => setActiveRoom(room)}
                            className={`w-full text-left p-2 rounded ${
                                activeRoom?.id === room.id
                                    ? 'bg-blue-100'
                                    : 'hover:bg-gray-100'
                            }`}
                        >
                            {room.name || 'Chat Room'}
                        </button>
                    ))}
                </div>
            </div>

            {/* Chat area */}
            <div className="flex-1 flex flex-col">
                {activeRoom ? (
                    <>
                        {/* Chat header */}
                        <div className="p-4 border-b flex justify-between items-center">
                            <h2 className="text-lg font-semibold">
                                {activeRoom.name || 'Chat Room'}
                            </h2>
                            {!isInCall ? (
                                <button
                                    onClick={startCall}
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                >
                                    Start Video Call
                                </button>
                            ) : (
                                <button
                                    onClick={endCall}
                                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                >
                                    End Call
                                </button>
                            )}
                        </div>

                        {/* Video call area */}
                        {isInCall && (
                            <div className="h-64 bg-gray-900 flex justify-center gap-4 p-4">
                                <video
                                    ref={userVideo}
                                    autoPlay
                                    playsInline
                                    muted
                                    className="h-full bg-black"
                                />
                                <video
                                    ref={partnerVideo}
                                    autoPlay
                                    playsInline
                                    className="h-full bg-black"
                                />
                            </div>
                        )}

                        {/* Messages */}
                        <div className="flex-1 overflow-y-auto p-4 space-y-4">
                            {messages.map(message => (
                                <div
                                    key={message.id}
                                    className={`flex ${
                                        message.sender_id === user.id
                                            ? 'justify-end'
                                            : 'justify-start'
                                    }`}
                                >
                                    <div
                                        className={`max-w-[70%] px-4 py-2 rounded-lg ${
                                            message.sender_id === user.id
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-100'
                                        }`}
                                    >
                                        <p className="text-sm font-medium mb-1">
                                            {message.sender_name}
                                        </p>
                                        <p>{message.content}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Message input */}
                        <form onSubmit={handleSendMessage} className="p-4 border-t">
                            <div className="flex gap-2">
                                <input
                                    type="text"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    className="flex-1 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                                    placeholder="Type a message..."
                                />
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                                >
                                    Send
                                </button>
                            </div>
                        </form>
                    </>
                ) : (
                    <div className="flex-1 flex items-center justify-center text-gray-500">
                        Select a chat to start messaging
                    </div>
                )}
            </div>

            {/* New room modal */}
            {showNewRoom && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full">
                        <h3 className="text-lg font-semibold mb-4">Create New Chat Room</h3>
                        <form onSubmit={handleCreateRoom}>
                            <input
                                type="text"
                                value={newRoomName}
                                onChange={(e) => setNewRoomName(e.target.value)}
                                className="w-full rounded-lg border-gray-300 mb-4"
                                placeholder="Room name"
                                required
                            />
                            <div className="flex justify-end gap-2">
                                <button
                                    type="button"
                                    onClick={() => setShowNewRoom(false)}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Incoming call modal */}
            {receivingCall && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg p-6">
                        <h3 className="text-lg font-semibold mb-4">Incoming Video Call</h3>
                        <div className="flex justify-center gap-2">
                            <button
                                onClick={answerCall}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                Answer
                            </button>
                            <button
                                onClick={() => setReceivingCall(false)}
                                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                            >
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chat;
