import React, { useState, useEffect } from 'react';
import api from '../utils/api';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        project_name: '',
        client_name: '',
        contact_info: { email: '', phone: '' },
        initial_price: '',
        annual_price: '',
        domain_provider: '',
        payment_method: ''
    });

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await api.get('/clients');
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/clients', formData);
            setShowForm(false);
            setFormData({
                project_name: '',
                client_name: '',
                contact_info: { email: '', phone: '' },
                initial_price: '',
                annual_price: '',
                domain_provider: '',
                payment_method: ''
            });
            fetchClients();
        } catch (error) {
            console.error('Error creating client:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold">Clients</h1>
                <button
                    onClick={() => setShowForm(!showForm)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    {showForm ? 'Cancel' : 'Add Client'}
                </button>
            </div>

            {showForm && (
                <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Project Name</label>
                            <input
                                type="text"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.project_name}
                                onChange={(e) => setFormData({ ...formData, project_name: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Client Name</label>
                            <input
                                type="text"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.client_name}
                                onChange={(e) => setFormData({ ...formData, client_name: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Contact Email</label>
                            <input
                                type="email"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.contact_info.email}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    contact_info: { ...formData.contact_info, email: e.target.value }
                                })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Contact Phone</label>
                            <input
                                type="tel"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.contact_info.phone}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    contact_info: { ...formData.contact_info, phone: e.target.value }
                                })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Initial Price</label>
                            <input
                                type="number"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.initial_price}
                                onChange={(e) => setFormData({ ...formData, initial_price: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Annual Price</label>
                            <input
                                type="number"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.annual_price}
                                onChange={(e) => setFormData({ ...formData, annual_price: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Domain Provider</label>
                            <input
                                type="text"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.domain_provider}
                                onChange={(e) => setFormData({ ...formData, domain_provider: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Payment Method</label>
                            <input
                                type="text"
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                value={formData.payment_method}
                                onChange={(e) => setFormData({ ...formData, payment_method: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Create Client
                        </button>
                    </div>
                </form>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {clients.map(client => (
                    <div key={client.id} className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-2">{client.project_name}</h3>
                        <p className="text-gray-600 mb-4">{client.client_name}</p>
                        <div className="space-y-2">
                            <p>Initial Price: ${client.initial_price}</p>
                            <p>Annual Price: ${client.annual_price}</p>
                            <p>Next Payment: {new Date(client.next_payment_date).toLocaleDateString()}</p>
                            <p>Domain Provider: {client.domain_provider}</p>
                            <p>Payment Method: {client.payment_method}</p>
                        </div>
                         <div className="mt-4 pt-4 border-t flex justify-end">
                            <button
                                onClick={() => alert('Edit functionality coming soon')}
                                className="text-blue-500 hover:text-blue-700 mr-4"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => alert('Delete functionality coming soon')}
                                className="text-red-500 hover:text-red-700"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Clients;
