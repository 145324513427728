import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Sidebar = () => {
    const location = useLocation();
    const { user } = useAuth();

    const navItems = [
        { path: '/', label: 'Dashboard' },
        { path: '/clients', label: 'Clients' },
        { path: '/chat', label: 'Chat' }
    ];

    if (user?.role === 'admin') {
        navItems.push({ path: '/units', label: 'Units' });
    }

    return (
        <div className="w-64 bg-gray-800 min-h-screen p-4">
            {navItems.map((item) => (
                <Link
                    key={item.path}
                    to={item.path}
                    className={`block py-2 px-4 rounded mb-2 ${
                        location.pathname === item.path
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700'
                    }`}
                >
                    {item.label}
                </Link>
            ))}
        </div>
    );
};

export default Sidebar;
